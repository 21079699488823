<template>
	<div
		v-show="show"
		data-component="date-picker"
	>
		<date-pick
			v-model="date"
			:pickTime="true"
			:format="'YYYY-MM-DD HH:mm'"
			:displayFormat="'YYYY.MM.DD H:mm A'"
			:hasInputElement="false"
			:isDateDisabled="isPastDate"
		/>
	</div>
</template>

<script>
	import DatePick 		from 'vue-date-pick';
	import 'vue-date-pick/dist/vueDatePick.css';

	export default {
		components: {
			DatePick
		},
		props: {
			initialDate: {
				type: String,
				default: ''
			},
			show: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			date: ''
		}),
		watch: {
			date (newVal) {
				this.$emit('input', newVal);
			}
		},
		created () {
			this.date = this.initialDate;
		},
		methods: {
			isPastDate (date) {
				const currentDate = new Date();
				const lastDay = 3600000 * 24; // 1hr * 24
				return date < currentDate - lastDay;
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='date-picker'] {
		width: 300px;
		margin-top: -300px;
		position: absolute;
	}

</style>

<template>
	<div data-route>
		<page-header
			heading="Edit lesson details"
		/>
		<div data-element="main">
			<lesson-edit />
		</div>
	</div>
</template>

<script>

	import PageHeader   from '@/components/ui/PageHeader';
	import LessonEdit  from '@/components/lessons/LessonEdit';
	import actionClick  from '@/mixins/actionClick';
	import routeParams  from '@/mixins/routeParams';

	export default {
		metaInfo: {
			title: 'Edit lesson details'
		},
		components: {
			PageHeader,
			LessonEdit
		},
		mixins: [actionClick, routeParams],
		data: () => ({
			lesson: {
				title:            '',
				description:      ''
			}
		}),
		computed: {
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
			},
			getBreadcrumbRoute () {
				return this.getLessonRoute;
			}
		}
	};
</script>

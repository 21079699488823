<template>
	<div data-component="lesson-edit">
		<live-banner
			v-show="lesson.live && lesson.schedule.startsAt"
			:schedule="lesson.schedule"
			:isLive="lesson.live"
			:caption="'Live lesson'"
		/>
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="formLoaded"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="lesson.title"
			/>
			<input-field
				name="Description"
				type="text"
				rules="required"
				v-model="lesson.description"
			/>
			<div v-if="lesson.live">
				<input-field
					name="Live zoom link"
					type="text"
					:rules="validateZoomLink"
					v-model="lesson.schedule.zoomLink"
					autocomplete="off"
				/>
				<wysiwyg-field
					name="Live lesson notes"
					type="text"
					:rules="null"
					v-model="lesson.notes"
				/>
				<div
					@click="addPicker"
					v-click-outside="removePicker"
					v-if="!hasLessonStarted"
				>
					<input-field
						@focus="addPicker"
						name="Scheduled start time"
						type="text"
						readonly
						:rules="validateLiveFields"
						v-model="shadowDate"
					/>
					<date-picker
						v-model="getStartTime"
						:show="showPicker"
					/>
				</div>
				<text-note
					v-else
					name="Scheduled start time"
					type="text"
					:value="shadowDate"
				/>
				<checkbox-field
					name="Will upload a replay after lesson completion"
					v-model="lesson.schedule.willUpload"
				/>
				<input-field
					v-if="getShowRecordingUpload"
					name="Zoom replay link"
					type="text"
					rules="url"
					v-model="lesson.schedule.zoomDownload"
				/>
			</div>
			<actions
				:actions="getActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField          from '@/components/forms/InputField.vue';
	import Actions             from '@/components/ui/Actions.vue';
	import api                 from '@/services/api';
	import CheckboxField       from '@/components/forms/CheckboxField.vue';
	import DatePicker          from '@/components/forms/DatePicker.vue';
	import LiveBanner          from '@/components/ui/LiveBanner.vue';
	import TextNote            from '@/components/forms/TextNote.vue';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';

	export default {

		components: {
			DatePicker,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			InputField,
			CheckboxField,
			LiveBanner,
			TextNote,
			Actions
		},
		mixins: [actionClick, invalidFormMessage, routeParams],
		props: {
			isCreate: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			lesson: {
				title:            	'',
				description:      	'',
				live: 							false,
				notes: 							'',
				schedule: {
					startsAt:      null,
					zoomDownload:  '',
					willUpload:    false,
					zoomLink:      null,
					startedAt:     null,
					endedAt:       null
				},
				media:           []
			},
			course: {
				live: false
			},
			formLoaded:  false,
			showPicker:  false,
			shadowDate:  '',
			isWaiting:   undefined
		}),
		computed: {
			getLessonTitle () {
				if (!this.lesson) {
					return false;
				}
				return this.lesson.title;
			},
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/${this.getContextSlug}/courses/${this.getCourseId}`;
			},
			getLessonsRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons`;
			},
			getLessonRoute () {
				if (this.isCreate) {
					return false;
				}
				return `${this.getLessonsRoute}/${this.getLessonId}`;
			},
			getSuccessRoute () {
				return this.getCourseRoute;
			},
			validateLiveFields () {
				return this.lesson.live ? 'required' : null;
			},
			validateZoomLink () {
				return this.validateLiveFields ? 'required|url' : null;
			},
			getActions () {
				return {
					primary: [
						{
							text: this.isCreate ? 'Create' : 'Save',
							actionId: 'submit',
							btnId: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							route: this.getCourseRoute
						}
					]
				};
			},
			getStartTime: {
				get () {
					if (!this.lesson.schedule.startsAt) {
						return '';
					}
					return this.lesson.schedule.startsAt;
				},
				set (value) {
					this.shadowDate = this.getDateTime(value);
					this.lesson.schedule.startsAt = this.getISO(value);
				}
			},
			getShowRecordingUpload () {
				if (!this.lesson.schedule.endedAt) {
					return false;
				}
				return true;
			},
			hasLessonStarted () {
				if (this?.lesson?.schedule?.endedAt ||
					this.lesson?.schedule?.startedAt) {
					return true;
				}
				return false;
			},
			getBreadcrumbs () {
				return [
					(this.getContext === 'admin' ?
						{
							path: '/admin',
							text: 'Admin'
						} : false
					),
					(this.getContext === 'teaching' ?
						{
							path: '/teaching',
							text: 'Teaching'
						} : false
					),
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.lesson?.courseTitle?.length ? this.lesson.courseTitle : 'Course'
					},
					(!this.isCreate ?
						{
							path: this.getLessonRoute,
							text: this.getLessonTitle
						} : false
					),
					(!this.isCreate ?
						{
							path: `${this.getLessonRoute}/edit`,
							text: 'Edit'
						} : false
					),
					(this.isCreate ?
						{
							path: `${this.getCoursesRoute}/create`,
							text: 'New lesson'
						} : false
					)
				].filter(Boolean);
			}
		},
		watch: {
			'lesson.schedule.startsAt' (newVal) {
				this.shadowDate = this.getDateTime(newVal);
			}
		},
		created () {
			this.init();
		},
		methods: {
			async init () {
				if (this.isCreate) {
					await this.setLessonIsLive();
					this.formLoaded = true;
					this.$store.commit('ui/setBreadcrumbs', {
						breadcrumbs: this.getBreadcrumbs
					});
					this.$store.commit('ui/setLoadingIsHidden');
					return;
				}
				this.setLesson();
			},
			async setLesson () {
				const lesson = await api[this.getApiRoot].getLessonById({
					lessonId: this.getLessonId
				});
				if (!lesson) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.lesson = {
					...this.lesson,
					...lesson,
					status: 'published'
				};
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async setLessonIsLive () {
				const course = await api[this.getApiRoot].getCourseById({
					courseId: this.getCourseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.lesson.live = course.live;
			},
			getISO (value) {
				return this.$store.getters['auth/getISOTimeString']({
					time: value
				});
			},
			getDateTime (value) {
				return this.$store.getters['auth/getTimeInLocalTimezone']({
					time: value
				});
			},
			addPicker () {
				this.showPicker = true;
			},
			removePicker () {
				this.showPicker = false;
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				const	actionText = this.isCreate ? 'created' : 'updated';
				let lesson;
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				if (this.isCreate) {
					lesson = await api.teaching.createLessonByCourseId({
						lesson: this.lesson,
						courseId: this.getCourseId
					});
					if (!lesson) {
						this.isWaiting = undefined;
						return;
					}
				} else {
					lesson = await api[this.getApiRoot].updateLessonById({
						fields: [
							'id'
						],
						lesson: {
							...this.lesson,
							deals: this.lesson.deals.map(item => item.id)
						},
						lessonId: this.getLessonId
					});
					if (!this.getLessonId) {
						this.isWaiting = undefined;
						return;
					}
				}
				this.isWaiting = undefined;
				await this.$router.push(this.getSuccessRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The lesson has been ' + actionText
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='lesson-edit'] {}
</style>

<template>
	<div
		data-component="live-banner"
		v-if="getIsVisible"
		:data-color="liveStatusColor"
	>
		{{ caption }} {{ liveStatus }}

		<p
			v-if="hasMedia"
		>
			This lesson has been recorded for playback. View the video below.
		</p>
	</div>
</template>

<script>
	export default {
		props: {
			schedule: {
				type: Object,
				default: () => {}
			},
			isLive: {
				type: Boolean,
				default: false
			},
			caption: {
				type: String,
				default: ''
			},
			hasMedia: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getIsVisible () {
				if (!this.schedule ||
						!this.isLive ||
						!this.schedule?.startsAt) {
					return false;
				}
				return true;
			},
			liveStatus () {
				if (!this.schedule) {
					return false;
				}
				const ends     = this.schedule.endedAt;
				const starts   = this.schedule.startsAt;
				const started  = this.schedule.startedAt;
				if (ends) {
					return 'ended at: ' + this.getDateTime(ends);
				} else if (started) {
					return 'started at: ' + this.getDateTime(started);
				} else {
					return 'scheduled for: ' + this.getDateTime(starts);
				}
			},
			liveStatusColor () {
				if (!this.schedule) {
					return false;
				}
				const ends = this.schedule?.endedAt;
				const started = this.schedule?.startedAt;
				return ends ? 'red' : started ? 'green' : 'yellow';
			}
		},
		methods: {
			getDateTime (value) {
				return this.$store.getters['auth/getTimeInLocalTimezone']({
					time: value
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='live-banner'] {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		min-height: 30px;
		font-weight: 700;
		padding: 3px 10px;
		border-radius: 3px;
		margin: 5px 0 10px;

		&, p {
			color: $c-white;
			font-size:rem(11);
		}

		&[data-color='red'] {
			background: red;
		}
		&[data-color='yellow'] {
			background: yellow;
			color: #000;
		}
		&[data-color='green'] {
			background: green;
		}
	}
</style>
